<template>
    <h1>Mes boutiques</h1>
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox">
                <div class="ibox-title">
                    <div class="form-group">
                        <select @change="onChange($event)" class="form-control">
                            <option v-for="etablissement in etablissements" :key="etablissement.id" :value="etablissement.uuid">
                                {{ etablissement.titre }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="ibox-content">
                    
                    <div class="row" v-if="etablissement && etablissement.type && 
                    (etablissement.type.label == 'domaine' || 
                    etablissement.type.label == 'entreprises-dans-le-domaine-du-vin' ||
                    etablissement.type.label == 'domaines-maisons-viticoles'
                    )">
                        <div class="col-md-12" >
                            <h4>Mes options de boutique</h4>
                            <div class="form-group">
                                <label for="">Activation du mode boutique ?</label>
                                <select name="" id="" v-model="etablissement.has_store" class="form-control">
                                    <option :value="true">Oui</option>
                                    <option :value="false">Non</option>
                                </select>
                                <button @click="showOptions = !showOptions" class="btn btn-default"><i class="fa fa-gear"></i></button>
                            </div>
                            <template v-if="etablissement.has_store && showOptions">
                              <!-- <div class="form-group">
                                  <label for="">Gestion de la livraison</label>
                                  <select name=""  v-model="etablissement.boutique_options.has_livraison" class="form-control">
                                      <option :value="false">Livraison via Trinch</option>
                                      <option :value="true">Livraison gérée par l'établissement</option>
                                  </select>
                              </div> -->
                              <template v-if="etablissement.boutique_options.has_livraison">
                                <div class="form-group">
                                    <label for="">Tarif HT livraison bouteille à l'unité</label>
                                    <input type="text" name="tarif_livraison_1" class="form-control" v-model="etablissement.boutique_options.tarif_livraison_1">
                                </div>
                                <div class="form-group">
                                    <label for="">Tarif HT livraison bouteille par caisse de 3</label>
                                    <input type="text" name="tarif_livraison_3" class="form-control" v-model="etablissement.boutique_options.tarif_livraison_3">
                                </div>
                                <div class="form-group">
                                    <label for="">Tarif HT livraison bouteille par caisse de 6</label>
                                    <input type="text" name="tarif_livraison_6" class="form-control" v-model="etablissement.boutique_options.tarif_livraison_6">
                                </div>
                                <div class="form-group">
                                    <label for="">Tarif HT limite franco de port (0 = no limit)</label>
                                    <input type="text" name="tarif_franco" class="form-control" v-model="etablissement.boutique_options.tarif_franco">
                                </div>
                                <div class="form-group">
                                    <label for="">Délai de livraison estimé (jours)</label>
                                    <input type="text" name="delai_livraison" class="form-control" v-model="etablissement.boutique_options.delai_livraison">
                                </div>
                                <div class="form-group">
                                    <label for="">Autoriser le retrait des produits à l'établissement :</label>                        
                                    <select  v-model="etablissement.has_retrait" class="form-control">
                                      <option value="true">Oui</option>
                                      <option value="false">Non</option>
                                    </select>
                                </div>
                              </template>
                            </template>
                            <button @click="saveData()" class="btn btn-default pull-right">Valider</button>
                        </div>                        
                        <div class="col-md-6" v-if="etablissement.has_store">
                          <h4>Vos produits</h4>
                          <template v-for="produit of etablissement.produits" v-bind:key="produit.id">
                            <div class="ibox-content" v-if="produit && !produit.in_store">
                              <div class="row">
                                  <div class="col-md-4">
                                      <img :src="produit.image.full_path + '?h=200'" alt="" class="max100">
                                  </div>
                                  <div class="col-md-8">
                                      <h3>{{ produit.titre }}</h3>
                                      <span>{{ produit.prix }} €</span>
                                      <p>STOCK : {{ produit.stock }}</p>                  
                                      <div class="form-group">
                                        <select name="" id="" v-model="produit.store_pack_quantity" class="form-control">
                                          <option :value="1">A l'unité</option>
                                          <option :value="3">Caisse de 3</option>
                                          <option :value="6">Caisse de 6</option>
                                        </select>
                                      </div>
                                      
                                      <button @click="addToStore(produit)" class="btn btn-default"><i ></i>Ajouter à la boutique</button>
                                  </div>
                              </div>
                            </div>                            
                          </template>
                        </div>
                        <div class="col-md-6" v-if="etablissement.has_store">
                          <h4>Vos produits en boutique</h4>
                          <template v-for="produit of etablissement.produits" v-bind:key="produit.id">
                            <div class="ibox-content" v-if="produit && produit.in_store">
                              <div class="row">
                                  <div class="col-md-4">
                                      <img :src="produit.image.full_path + '?h=200'" alt="" class="max100">
                                  </div>
                                  <div class="col-md-8">
                                      <h3>{{ produit.titre }}</h3>
                                      <span>{{ produit.prix }} €</span>
                                      <p>STOCK : {{ produit.stock }}</p>
                                      <p>Conditionnement : {{ conditionnements[produit.store_pack_quantity] }}</p>
                                     
                                      
                                      <button @click="removeStore(produit)" class="btn btn-default"><i ></i>Retirer de la boutique</button>
                                  </div>
                              </div>
                            </div>  
                          </template>
                        </div>
                    </div>
                    <div v-else>
                      Cet établissement n'est pas éligible au mode boutique.
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { h } from 'vue'

export default {
  name: 'Reservations',
  data() {
    return {
      etablissement: {},
      reservations: [],
      showOptions: false,
      conditionnements: {
        1 : 'A l\'unité',
        3 : 'Caisse de 3',
        6 : 'Caisse de 6',
      }
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData',
    etablissements: function(newValue) {
      if (newValue && newValue[0]) {
        this.fetchReservations(newValue[0].uuid);
        //this.etablissement = newValue[0];
      }
    }
  },
  methods: {
    fetchData () {
      this.$store.dispatch('getEtablissements').then(() =>  {
      });
    },
    fetchReservations (uuid) {
      axios
        .all([
          axios.get(process.env.VUE_APP_API + "/producteur/etablissement/" + uuid),
          axios.get(process.env.VUE_APP_API + "/web/etablissement/reservations/" + uuid)
        ])
        .then(
          axios.spread((...responses) => {
            this.etablissement = responses[0].data;
            if(!this.etablissement.boutique_options){
              //this.etablissement.boutique_options = null
              this.etablissement.boutique_options = {            
                tarif_livraison_1: null,
                tarif_livraison_3: null,
                tarif_livraison_6: null,
                tarif_franco: null,
                delai_livraison: null,
                has_livraison: true,
              }
            }
            this.reservations = responses[1].data;
            // loading.close();
          })
        )
        .catch((error) => {
            console.log("ERROR", error);
        });
    },
    onChange (e) {
      this.fetchReservations(e.target.value);
    },
    saveData() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        axios
          .post(
            process.env.VUE_APP_API + "/producteur/etablissement/" +
              this.etablissement.uuid,
            this.etablissement
          )
          .then((resp) => {
            loading.close();
            this.etablissement = resp.data;
            this.$notify({
              title: 'Etablissement enregistré',
              message: h('i', { style: 'color: teal' }, '')
            });
          })
          .catch((error) => {
            console.log("ERROR", error);
          });
    },
    addToStore(produit){
      produit.in_store = true;
      this.saveData()
    },
    removeStore(produit){
      produit.in_store = false;
      this.saveData()
    }
  },
  computed: {
    etablissements () {
      return this.$store.state.etablissements.etablissements
    },
  },
  components: {
  },
};
</script>